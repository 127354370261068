/* Container for the whole page */
.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to bottom, #000000, #003cff);
}

.logoIcon {
  display: flex;
  width: 40px; /* Adjust size as needed */
  height: 40px;
  align-items: center;
  justify-content: center;
  margin: 0.5rem auto;
  padding: 0.75rem 1rem;
}

/* Card Wrapper */
.card {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}

/* Login Section */
.loginSection {
  margin-bottom: 2rem;
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: #1e293b;
  text-align: center;
  margin-bottom: 1rem;
}

.form {
  display: flex;
  flex-direction: column;
}

.input {
  width: 100%;
  padding: 0.75rem;
  margin: 0.5rem 0;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  font-size: 16px;
}

.recaptchaContainer {
  margin: 1rem 0;
  display: flex;
  justify-content: center;
}

.optionsRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
}

.checkbox {
  margin-right: 0.5rem;
}

.link {
  font-size: 14px;
  color: #3b82f6;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.loginButton {
  width: 100%;
  padding: 0.75rem;
  background-color: #3b82f6;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 1rem;
}

.loginButton:hover {
  background-color: #2563eb;
}

.signupPrompt {
  font-size: 14px;
  margin-top: 1rem;
  text-align: center;
  color: #6b7280;
}

/* Providers Section */
.providersSection {
  margin-top: 1rem;
  text-align: center;
}

.providersTitle {
  font-size: 18px;
  margin-bottom: 1rem;
  color: #1e293b;
}

.alternateLogin {
  text-align: center;
}

.secondaryButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem auto;
  padding: 0.75rem 1rem;
  background-color: #ffffff;
  color: #1f2937;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease, color 0.3s ease;
  text-align: center;
  width: 100%;
}
