/* App.module.css */

.appContainer {
    display: flex;
    min-height: 100vh; /* Allow content to grow */
  }
  
  .contentWrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    transition: margin-left 0.3s ease-in-out; /* Smooth adjustment */
    overflow-y: auto; /* Enable scrolling for the main content */
  }
  
  .mainContent {
    flex-grow: 1;
    padding: 20px;
  }
  
  .footer {
    background-color: #f7f7f8;
    text-align: center;
    padding: 10px 20px;
  }
