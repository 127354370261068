.dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 900px;
  margin: 0 auto;
}

/* Header with Year in Top Right */
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h2 {
  font-size: 24px;
  color: #333;
}

.year {
  font-size: 18px;
  font-weight: bold;
  color: #777;
}

/* Filter Container */
.filterContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.filterContainer label {
  font-size: 16px;
  color: #444;
}

.filterContainer select {
  padding: 5px 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

/* Chart Container */
.chartContainer {
  width: 100%;
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

h3 {
  font-size: 20px;
  color: #555;
  margin-bottom: 10px;
  text-align: center;
}

/* Tooltip Styling */
.recharts-tooltip-wrapper {
  font-size: 14px;
}

.recharts-tooltip-label {
  font-weight: bold;
}

.recharts-tooltip-item-list {
  list-style: none;
  padding: 0;
}

.recharts-tooltip-item {
  margin: 5px 0;
}
