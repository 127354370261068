/* Sidebar.module.css */


/* Container for the entire sidebar layout */
.sidebarContainer {
  position: relative;
}

/* User Menu Container */
.userMenu {
  position: relative;
}

.userMenuButton {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #007bff;
  text-decoration: none;
}

.userMenuButton:hover {
  color: #0056b3;
}

/* User Details Section */
.userDetails {
  padding: 10px 20px;
  background-color: #fff;
  text-align: left;
}

.userName {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  color: #333;
}

.userEmail {
  font-size: 14px;
  margin: 0;
  color: #666;
}

/* Divider Line */
.divider {
  border: 0;
  height: 1px;
  background-color: #e0e0e0;
  margin: 10px 0;
}
/* Dropdown Menu */
.dropdownMenu {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  width: 225px;
  z-index: 1000;
}

.dropdownMenu ul {
  list-style: none;
  padding: 10px 0;
  margin: 0;
}

.dropdownMenu li {
  padding: 10px 20px;
}

.dropdownMenu li:hover {
  background-color: #f7f7f8;
}

.dropdownMenu a {
  text-decoration: none;
  color: #333;
  font-size: 14px;
}

.dropdownMenu a:hover {
  color: #007bff;
}


/* Breadcrumb Bar */
.breadcrumbBar {
  position: fixed;
  top: 0;
  left: 0;
  height: 60px;
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  padding: 0 20px;
  z-index: 1000;
  transition: margin-left 0.3s ease-in-out; /* Smooth adjustment for movement */
}

/* Adjust breadcrumb container width */
.breadcrumbContainer {
  display: flex;
  align-items: center;
  width: 100%; /* Full width for proper alignment */
  justify-content: space-between;
}

/* Toggle button inside breadcrumb bar */
.breadcrumbToggleButton {
  background: none;
  border: none;
  font-size: 24px;
  color: #007bff;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

.breadcrumbToggleButton:hover {
  color: #0056b3;
}

/* Breadcrumb text and links */
.breadcrumbs {
  font-size: 14px;
  color: #555;
  display: flex;
  align-items: center;
  gap: 5px; /* Add spacing between breadcrumb items */
  flex-wrap: wrap; /* Allow breadcrumbs to wrap if necessary */
}

.breadcrumbs a {
  color: #007bff;
  text-decoration: none;
}

.breadcrumbs a:hover {
  text-decoration: underline;
}



/* Top Bar */
.topBar {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 10px;
  background-color: #f7f7f8;
  border-bottom: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  position: fixed;
  height: 60px;
  z-index: 1000;
  transition: width 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.topBar.open {
  width: 250px; /* Full width when sidebar is open */
}

.topBar.closed {
  width: 60px; /* Narrow width when sidebar is closed */
  transform: translateX(-190px); /* Adjusts alignment to match sidebar */
}

/* Brand (Home button with fox icon) */
.brand {
  font-size: 24px;
  text-decoration: none;
  color: #333;
  margin-right: auto;
  opacity: 1;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.brand.closed {
  opacity: 0; /* Hide the fox */
  transform: translateX(-50px); /* Moves the fox out of view */
}

/* Primary Toggle Button */
.toggleButton {
  background: none;
  border: none;
  font-size: 24px;
  color: #007bff;
  cursor: pointer;
  margin-left: auto;
  transition: color 0.3s ease-in-out;
}

.toggleButton:hover {
  color: #0056b3;
}

/* Sidebar */
.sidebar {
  width: 250px;
  height: calc(100vh - 60px); /* Full height minus breadcrumb bar */
  /* background-color: #f7f7f8; */
  border-right: 1px solid #e0e0e0;
  padding: 20px;
  position: fixed;
  top: 60px; /* Below breadcrumb bar */
  display: flex;
  flex-direction: column;
  transform: translateX(0); /* Default state: fully visible */
  transition: transform 0.3s ease-in-out;
  z-index: 999;
}

.sidebar.closed {
  transform: translateX(-250px); /* Slide out of view */
}

.sidebar.open {
  transform: translateX(0); /* Fully visible */
}

/* Secondary Toggle Button */
.secondaryToggleButton {
  position: fixed;
  top: 10px;
  left: 10px; /* Always visible on the screen */
  background: none;
  border: none;
  font-size: 24px;
  color: #007bff;
  cursor: pointer;
  z-index: 1002; /* Ensure it's above the sidebar */
}

.secondaryToggleButton:hover {
  color: #0056b3;
}

/* Navigation Items */
.navList {
  list-style: none;
  padding: 0;
  margin: 0;
  flex-grow: 1;
}

.navItem {
  margin-bottom: 15px;
}

.navLink {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-radius: 5px;
  color: #555;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
}

.navLink:hover {
  background-color: #e0e0e0;
  color: #333;
}

/* Footer */
.footer {
  margin-top: auto;
}

.footerLink {
  font-size: 14px;
  color: #007bff;
  text-decoration: none;
}

.footerLink:hover {
  text-decoration: underline;
}
