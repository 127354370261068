.createCategoryContainer {
  text-align: center;
  padding: 20px;
}

.categoryButtons {
  display: flex;
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  gap: 30px; /* Increase space between buttons */
  flex-wrap: wrap; /* Makes it responsive for smaller screens */
  margin-top: 30px; /* Increase margin for better spacing */
}

.categoryButton {
  background: none;
  border: none;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.categoryButton:hover {
  transform: scale(1.1);
}

.categoryButton.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.categoryImage {
  width: 180px; /* Make the images bigger */
  height: auto;
  margin: auto;
  position: center;
  max-width: 100%;
  border-radius: 12px; /* Slightly rounder corners */
  box-shadow: 3px 6px 10px rgba(0, 0, 0, 0.25); /* Enhance shadow */
}

/* Responsive Styles */
@media (max-width: 600px) {
  .categoryButtons {
    flex-direction: column;
    gap: 20px; /* Slightly reduce gap for smaller screens */
  }

  .categoryImage {
    width: 140px; /* Adjust size for smaller screens */
  }
}
