.feedContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  /* Left Sidebar - Profile & Navigation */
  .sidebar {
    width: 250px;
    background: #ffffff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .profile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .profilePic {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  .navLinks {
    margin-top: 15px;
  }
  
  .navLinks a {
    display: block;
    text-decoration: none;
    color: #333;
    font-size: 16px;
    padding: 10px;
    transition: background 0.2s ease-in-out;
  }
  
  .navLinks a:hover {
    background: #f4f4f4;
    border-radius: 5px;
  }
  
  /* Main Feed - Center */
  .mainFeed {
    flex: 1;
    max-width: 600px;
    margin: 0 20px;
  }
  
  .feedContent {
    background: white;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Right Sidebar - Trending Beers */
  .trending {
    width: 250px;
    background: #ffffff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .trending h4 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .trending ul {
    list-style: none;
    padding: 0;
  }
  
  .trending li {
    font-size: 16px;
    padding: 5px 0;
  }
  
  .trending li:hover {
    font-weight: bold;
    cursor: pointer;
  }
  