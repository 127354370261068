/* Navbar styles */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: #0d1117;
    color: white;
  }

  .navLinks {
    display: flex;
    list-style: none;
    gap: 1.5rem;
  }
  
  .navItem {
    position: relative;
    cursor: pointer;
  }
  
  .navItem:hover .dropdown {
    display: block;
  }
  
  .dropdown {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #161b22;
    padding: 0.5rem;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    z-index: 10;
  }
  
  .dropdown a {
    display: block;
    padding: 0.5rem 1rem;
    color: white;
    text-decoration: none;
  }
  
  .dropdown a:hover {
    background-color: #21262d;
  }
  
  .navActions {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .searchBox {
    padding: 0.5rem;
    border: 1px solid #30363d;
    border-radius: 4px;
    background-color: #161b22;
    color: white;
  }
  
  .searchBox::placeholder {
    color: #8b949e;
  }
  
  .signInButton,
  .signUpButton {
    display: inline-block;
    padding: 0.5rem 1rem;
    color: white;
    border-radius: 4px;
    text-align: center;
    text-decoration: none;
  }
  
  .signInButton {
    background-color: #238636;
    border: none;
  }
  
  .signInButton:hover {
    background-color: #2ea043;
  }
  
  .signUpButton {
    background-color: #161b22;
    border: 1px solid #30363d;
  }
  
  .signUpButton:hover {
    background-color: #21262d;
  }
  
  /* Home Container */
  .homeContainer {
    font-family: Arial, sans-serif;
    background-color: #0d1117;
    color: white;
    min-height: 100vh;
  }
  
  .header {
    text-align: center;
    padding: 2rem;
  }
  
  .header h1 {
    font-size: 2.5rem;
    line-height: 1.5;
    font-weight: bold;
  }
  
  /* Main container */
.homeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to bottom, #000000, #003cff);
  color: white;
  text-align: center;
  padding: 2rem;
}

/* Hero section styles */
.heroSection {
  margin-bottom: 2rem;
}

.title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.subtitle {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  color: #c9d1d9;
}

/* Button styles */
/* Adjusted button container to include spacing */
.buttonContainer {
  display: flex;
  justify-content: center;
  gap: 1rem; /* Adds space between buttons */
}

.signinMainButton,
.signupMainButton {
  padding: 0.75rem 2rem;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
}

.signinMainButton {
  background-color: #238636;
  color: white;
}

.signinMainButton:hover {
  background-color: #2ea043;
}


/* Adjusted Sign Up button with background color */
.signupMainButton {
  background-color: #0044cc; /* Adds a background color */
  border: none;
  color: white;
  padding: 0.75rem 2rem;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.signupMainButton:hover {
  background-color: #0033aa;
}

/* Image section styles */
.imageSection {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  max-width: 50%;
  height: auto;
  border-radius: 12px;
}
