/* Global Container */
.container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: linear-gradient(to bottom, #ffcc33, #ff9900); /* IPA beer color theme */
    color: #333;
    min-height: 100vh;
    gap: 20px;
    font-family: Arial, sans-serif;
  }
  
  /* Top Controls */
  .topControls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .searchBarContainer {
    flex: 1;
    display: flex;
    align-items: center;
  }
  
  .searchInput {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #cc6600;
    font-size: 16px;
    background: #fff3e0;
    color: #663300;
  }
  
  .globalControls {
    display: flex;
    gap: 10px;
  }
  
  .controlButton {
    padding: 8px 16px;
    background-color: #cc6600;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .controlButton:hover {
    background-color: #993d00;
  }
  
  /* Content */
  .content {
    display: flex;
    gap: 20px;
  }
  
  /* Beer List */
  .beerList {
    flex: 2;
    background: #ffebcc;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    max-height: calc(100vh - 160px);
  }
  
  .heading {
    margin-bottom: 15px;
    font-size: 22px;
    font-weight: bold;
    color: #993d00;
  }
  
  .typeSection {
    margin-bottom: 15px;
  }
  
  .typeHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffcc80;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .collapseButton {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
  }
  
  .cardContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .selectedPicks .cardContainer {
    grid-template-columns: 1fr;
  }
  
  .card {
    display: flex;
    align-items: flex-start;
    background: #ffdb99;
    border: 1px solid #cc6600;
    border-radius: 8px;
    padding: 15px;
    gap: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
  }
  
  .card:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }
  
  .flagStrip {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  
  .flag-usa {
    background: repeating-linear-gradient(
      90deg,
      #b22234,
      #b22234 10%,
      white 10%,
      white 20%
    ),
    linear-gradient(to right, #3c3b6e 50%, transparent 50%);
    background-size: 100% 100%, 30% 100%;
    background-repeat: no-repeat;
  }
  
  .flag-ireland {
    background: linear-gradient(to right, green, white, orange);
  }
  
  .flag-belgium {
    background: linear-gradient(to right, black, yellow, red);
  }
  
  .flag-mexico {
    background: linear-gradient(to right, green, white, red);
  }
  
  .flag-netherlands {
    background: linear-gradient(to right, red, white, blue);
  }
  
  .cardImage {
    width: 80px;
    height: auto;
    border-radius: 5px;
  }
  
  .cardContent {
    flex: 1;
  }
  
  .cardName {
    font-size: 18px;
    font-weight: bold;
    color: #663300;
    margin-bottom: 5px;
  }
  
  .cardType,
  .cardStats {
    font-size: 14px;
    color: #884d00;
    margin-bottom: 5px;
  }
  
  .cardDescription {
    font-size: 12px;
    color: #996633;
  }
  
  /* Selected Picks */
  .selectedPicks {
    flex: 1;
    background: #ffe6b3;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    max-height: calc(100vh - 160px);
  }
  
  .selectedPicks .heading {
    color: #cc6600;
  }
  
  .beerMeButton {
    width: 100%;
    padding: 12px;
    margin-top: 20px;
    background-color: #cc6600;
    color: white;
    font-size: 18px;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .beerMeButton:hover {
    background-color: #993d00;
    transform: scale(1.05);
  }

  
  /* Mobile Styles */
  @media (max-width: 768px) {
    .content {
      flex-direction: column;
    }
  
    .beerList {
      order: 1;
    }
  
    .selectedPicks {
      order: 2;
      margin-top: 20px;
    }
  
    .cardContainer {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
  }