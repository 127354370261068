.welcome {
    font-size: 18px;
    color: #555;
    margin-bottom: 20px;
    text-align: center;
  }
  
  /* Table Styling */
  .tableContainer {
    width: 100%;
    overflow-x: auto;
  }
  
  .rankingTable {
    width: 100%;
    border-collapse: collapse;
    background: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
  }
  
  th, td {
    padding: 12px;
    text-align: center;
    border-bottom: 1px solid #ddd;
  }
  
  th {
    background: #FF7F50;
    color: white;
    font-size: 16px;
  }
  
  tr:nth-child(even) {
    background: #f9f9f9;
  }
  
  tr:hover {
    background: #f1f1f1;
  }
  