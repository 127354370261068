/* Styling for the container holding the buttons and the grid */
.container {
    display: flex;
    flex-direction: column;
    height: 600px;
    width: 100%;
  }
  
  /* Styling for the button container */
  .buttonContainer {
    display: flex;
    justify-content: space-around;
    padding: 10px;
  }
  
  /* Styling for buttons with hover animation */
.agButton {
    padding: 10px 20px;
    background-color: #000000; /* Primary button color */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s; /* Smooth transitions for hover effects */
    outline: none; /* Remove outline added by browsers on focus */
    text-transform: uppercase; /* Optional: makes text uppercase for a more styled look */
  }
  
  .agButton:hover {
    background-color: #0056b3; /* Darker shade on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Simulate elevation with shadow */
    transform: translateY(-2px); /* Slightly raise the button */
  }
  
  .agButton:active {
    transform: translateY(1px); /* Push the button down when clicked */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Adjust shadow to simulate button press */
  }
  
  
  /* Responsive adjustments */
  @media (max-width: 600px) {
    .buttonContainer {
      flex-direction: column;  /* Stack buttons vertically on smaller screens */
      gap: 10px;  /* Space out buttons vertically */
    }
  
    .agButton {
      width: 100%;  /* Full width buttons are easier to tap on mobile */
    }
  }
  
  .linkbutton {
    background-color: transparent;
    border: none;
    color: #fe1ccd;
    cursor: pointer;
    display: inline;
    font-size: 16px;
    padding: 5px 10px;
    text-align: left;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  }
  
  .linkbutton:hover, .linkbutton:focus {
    color: #fe1ccd;
    background-color: #000000;
    outline: none;
  }

  
  /* Styling for the AG-Grid */
  .gridContainer {
    flex-grow: 1; /* Allow the grid to take up remaining space */
    padding: 10px; /* Padding around the grid for spacing */
  }
  