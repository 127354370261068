/* Main container for the reset page */
.resetContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #0d1117;
    padding: 2rem;
    color: white;
  }
  
  /* Box around the content */
  .resetBox {
    background-color: #161b22;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 100%;
    text-align: center;
  }
  
  /* Logo styles */
  .logoWrapper {
    margin-bottom: 1rem;
  }
  
  .logo {
    width: 50px;
    height: 50px;
  }
  
  /* Header styles */
  h1 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
  
  p {
    font-size: 1rem;
    margin-bottom: 1.5rem;
    color: #c9d1d9;
  }
  
  /* Form styles */
  .resetForm {
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  /* Center ReCAPTCHA within the form */
.recaptchaContainer {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}

.recaptchaContainer > div {
  width: 100%; /* Ensures ReCAPTCHA spans the full container */
  max-width: 300px; /* Matches the max-width of the form */
}

  
  label {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  
  .inputField {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: 1px solid #30363d;
    border-radius: 8px;
    background-color: #161b22;
    color: white;
  }
  
  .inputField::placeholder {
    color: #8b949e;
  }
  
  .resetButton {
    padding: 0.75rem;
    background-color: #238636;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .resetButton:hover {
    background-color: #2ea043;
  }
  
  .resetButton:disabled {
    background-color: #3c3c3c;
    cursor: not-allowed;
  }
  
  /* Back to login link */
  .backToLogin {
    margin-top: 1.5rem;
    font-size: 0.9rem;
    color: #8b949e;
  }
  
  .backToLogin a {
    color: #58a6ff;
    text-decoration: none;
  }
  
  .backToLogin a:hover {
    text-decoration: underline;
  }
  